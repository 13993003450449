import React from "react";
import "./card.css";
import { useLocation, useNavigate } from "react-router-dom";

const ResultPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { resultData } = location.state || {};

  function format2Digit(number) {
    // Convert the number to a string
    let numStr = number.toString();

    // Find the position of the decimal point
    let dotIndex = numStr.indexOf(".");

    // If there's no decimal point, add ".00" to the end
    if (dotIndex === -1) {
      return numStr + ".00";
    }

    // Extract the integer part and the decimal part
    let integerPart = numStr.substring(0, dotIndex);
    let decimalPart = numStr.substring(dotIndex + 1);

    // Ensure the decimal part has at least 2 digits
    if (decimalPart.length === 1) {
      return integerPart + "." + decimalPart + "0";
    } else if (decimalPart.length >= 2) {
      return integerPart + "." + decimalPart.substring(0, 2);
    }
  }

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <img
            className="signature-image"
            src="https://result.7college.du.ac.bd/rokey/logo.png"
            alt="University Logo"
          />
          <h2 className="header-large">UNIVERSITY OF DHAKA</h2>
          <h3 className="header-medium">Affiliated Seven College</h3>
          <p className="text-normal">Department of {resultData.sub_name}</p>
          <p className="text-normal">{resultData.exam_title}</p>
        </div>

        <div className="placeholder">Online Result Sheet</div>

        <table className="student-info">
          <tbody>
            <tr>
              <td>Student's Name</td>
              <td>{resultData.name}</td>
            </tr>
            <tr>
              <td>College Name</td>
              <td>{resultData.college_name}</td>
            </tr>
            <tr>
              <td>Registration No.</td>
              <td>{resultData.reg}</td>
            </tr>
            <tr>
              <td>Roll No.</td>
              <td>{resultData.roll}</td>
            </tr>
            <tr>
              <td>Session</td>
              <td>{resultData.session_name}</td>
            </tr>
            <tr>
              <td>Student Type</td>
              <td>{resultData.type_name}</td>
            </tr>
            {resultData.first_gpa && (
              <tr>
                <td>
                  1<sup>st</sup> Year GPA
                </td>
                <td>{format2Digit(resultData.first_gpa)}</td>
              </tr>
            )}
            {resultData.second_gpa && (
              <tr>
                <td>
                  2<sup>nd</sup> Year GPA
                </td>
                <td>{format2Digit(resultData.second_gpa)}</td>
              </tr>
            )}
            {resultData.third_gpa && (
              <tr>
                <td>
                  3<sup>rd</sup> Year GPA
                </td>
                <td>{format2Digit(resultData.third_gpa)}</td>
              </tr>
            )}
            {resultData.fourth_gpa && (
              <tr>
                <td>
                  4<sup>th</sup> Year GPA
                </td>
                <td>{format2Digit(resultData.fourth_gpa)}</td>
              </tr>
            )}
            {resultData.cgpa && (
              <tr>
                <td>Final CGPA</td>
                <td>{format2Digit(resultData.cgpa)}</td>
              </tr>
            )}
            <tr>
              <td>Result</td>
              <td>{resultData.result}</td>
            </tr>
          </tbody>
        </table>

        <table className="grades black-table" style={{ fontSize: "13px" }}>
          <thead>
            <tr>
              <th>Paper Code</th>
              <th>Course Title</th>
              <th>Letter Grade</th>
              <th>Grade Point</th>
              <th>Credit</th>
            </tr>
          </thead>
          <tbody>
            {resultData.courses.map((course, index) => (
              <tr key={index}>
                <td>{course.code}</td>
                <td style={{ paddingRight: "5px", textAlign: "left" }}>
                  {course.title}
                </td>
                <td>{course.lg}</td>
                <td>{format2Digit(course.gp)}</td>
                <td>{course.credit}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="signature-text">
          <img
            className="signature-image-right"
            src="https://result.7college.du.ac.bd/rokey/sign.png"
            alt="Signature"
          />
          <div>
            <h3 style={{ margin: "5px 0", fontSize: 19 }}>
              𝐌𝐝. 𝐁𝐚𝐡𝐚𝐥𝐮𝐥 𝐇𝐨𝐪𝐮𝐞 𝐂𝐡𝐨𝐰𝐝𝐡𝐮𝐫𝐲
            </h3>
            <p style={{ margin: "5px 0", fontSize: 18 }}>
              𝘊𝘰𝘯𝘵𝘳𝘰𝘭𝘭𝘦𝘳 𝘰𝘧 𝘌𝘹𝘢𝘮𝘪𝘯𝘢𝘵𝘪𝘰𝘯𝘴
            </p>
            <p style={{ margin: "5px 0", fontSize: 16 }}>𝘜𝘕𝘐𝘝𝘌𝘙𝘚𝘐𝘛𝘠 𝘖𝘍 𝘋𝘏𝘈𝘒𝘈</p>
          </div>
        </div>

        <div className="buttons">
          <button className="btn" onClick={() => navigate("/")}>
            Search Again
          </button>
          <button className="btn" onClick={(e) => window.print()}>
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
