import React, { useEffect, useState } from "react";
import "./result.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../componnet/image/logo.png";

const SearchResult = () => {
  const [programs, setPrograms] = useState([]);

  const navitate = useNavigate();
  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://secure.7college.du.ac.bd/ReactApis/get_pid"
        );
        setPrograms(response.data.programs);
      } catch (err) {
      } finally {
      }
    };
    fetchPrograms();
  }, []);

  const [selectedProgram, setSelectedProgram] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [yers, setYears] = useState([]);
  const [exams, setExams] = useState([]);

  const selectProgram = async (program_id) => {
    setSelectedProgram(program_id);

    try {
      const response = await axios.get(
        `https://secure.7college.du.ac.bd/ReactApis/get_yid?program_id=${program_id}`
      );
      setYears(response.data.years);
    } catch (err) {}
  };
  const yearChange = async (year_id) => {
    setSelectedYear(year_id);
    try {
      const response = await axios.get(
        `https://secure.7college.du.ac.bd/ReactApis/get_eid?program_id=${selectedProgram}&yid=${year_id}`
      );
      setExams(response.data.exams);
    } catch (err) {}
  };
  const [selectedExam, setSelectedExam] = useState();
  const [reg, setReg] = useState();
  const [roll, setRoll] = useState();
  const getResult = async () => {
    try {
      const response = await axios.get(
        `https://secure.7college.du.ac.bd/ReactApis/get_result?program_id=${selectedProgram}&yid=${selectedYear}&eid=${selectedExam}&reg=${reg}&roll=${roll}`
      );

      if (response.data.status === "success") {
        navitate("./results", {
          state: { resultData: response?.data?.result },
        });
      } else {
        alert("somthing want worng.");
      }
    } catch (err) {}
  };
  return (
    <div className="registration-form" style={{ margin: "auto" }}>
      <div
        className="registration-form-header"
        style={{ backgroundColor: "#513c72" }}
      >
        <img className="logo" src={logo} alt="logo" />
        <h4
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "20px",
            margin: "5px 0",
          }}
        >
          7 Colleges Affiliated with University of Dhaka
        </h4>
        <h5
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "18px",
            margin: "5px 0",
          }}
        >
          Result Archive
        </h5>
      </div>
      <div
        className="bordered"
        style={{ border: "1px solid #513c72", padding: "20px" }}
      >
        <div style={{ textAlign: "left" }}>
          <label>Select Program:</label>
          <select
            name="program"
            style={{ textAlign: "left", fontSize: 16 }}
            className="form-control"
            onChange={(e) => selectProgram(e.target.value)}
            value={selectedProgram}
            required
          >
            <option style={{ padding: "10px" }} value="">
              Select Program
            </option>
            {programs?.map((program) => (
              <option key={program.id} value={program.id}>
                {program.program_name}
              </option>
            ))}
          </select>
        </div>
        <div style={{ textAlign: "left" }}>
          <label>Select Year:</label>
          <select
            name="year"
            style={{ textAlign: "left", fontSize: 16 }}
            className="form-control"
            onChange={(e) => yearChange(e.target.value)}
            value={selectedYear}
            required
          >
            <option style={{ padding: "10px" }} value="">
              Select Year
            </option>
            {yers?.map((year) => (
              <option key={year.id} value={year.id}>
                {year.year_name}
              </option>
            ))}
          </select>
        </div>
        <div style={{ textAlign: "left" }}>
          <label style={{ padding: "5px" }}>Select Exam:</label>
          <select
            name="exam"
            style={{ textAlign: "left", fontSize: 16 }}
            className="form-control"
            onChange={(e) => setSelectedExam(e.target.value)}
            value={selectedExam}
            required
          >
            <option style={{ padding: "10px" }} value="">
              Select Exam
            </option>
            {exams?.map((exam) => (
              <option key={exam.id} value={exam.id}>
                {exam.exam_title}
              </option>
            ))}
          </select>
        </div>
        <div style={{ textAlign: "left" }}>
          <label>Registration Number:</label>
          <input
            type="number"
            name="registrationNumber"
            style={{ textAlign: "left", fontSize: 16 }}
            value={reg}
            onChange={(e) => setReg(e.target.value)}
            className="form-control"
            required
            placeholder="18723020193"
          />
        </div>
        <div style={{ textAlign: "left" }}>
          <label>Exam Roll:</label>
          <input
            type="number"
            name="examRoll"
            style={{ textAlign: "left", fontSize: 16 }}
            value={roll}
            onChange={(e) => setRoll(e.target.value)}
            className="form-control"
            required
            placeholder="123456"
          />
        </div>
        <div style={{ textAlign: "center", color: "white" }}>
          <button
            style={{ backgroundColor: "#513c72", color: "white", fontSize: 20 }}
            type="submit"
            onClick={getResult}
          >
            Search
          </button>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#513c72",
          height: 100,
          width: "auto",
          borderRadius: "0 0 5px 5px",
        }}
      >
        <div
          className="footer-text"
          style={{ textAlign: "center", fontSize: 17 }}
        >
          @ 𝟐𝟎𝟐𝟒 𝐔𝐧𝐢𝐯𝐞𝐫𝐬𝐢𝐭𝐲 𝐨𝐟 𝐃𝐡𝐚𝐤𝐚
          <br />
          Developed and Maintaining By Office of the Controller of Examinations
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
